import CONST from '@/config/const';
import { ucApi, userApi, assetApi, subApi, vipApi, hskFeeApi } from '@/config/api';
import { kycApi } from '@/config/kyc';
import { stayRoute } from '@/config/setup';
import { getOriginPath } from '@/lang/i18n';
import { removeCookie } from '@/utils/cookie';
import { toast } from '@/utils/toast';
import { WorkerTimer } from '@/utils/worker';
interface Userinfo {
  userId: string;
  registerType: number;
  mobile: string;
  nationalCode: string;
  email: string;
  bindGA: boolean;
  bindTradePwd: boolean;
  bindPassword: boolean;
  userType: number;  // 身份类型: 1. 零售 2. 机构 3. 个人（PI） 4. 综合账户 5. 交易员子账号 6. 授权交易员 7.临时身份
  verifyStatus: number;
  kycLevel: number;
  kycVerifyStatus: number;
  displayLevel: string;
  accounts: [];
  defaultAccountId: string;
  registerDate: string;
  openOption: boolean;
  openFuture: boolean;
  openMargin: boolean;
  learnWithdraw: boolean;
  learnDeposit: boolean;
  riskSettings: any;
  favorites: [];
  source: string;
  nickname: string;
  isAgent: number;
  customLabelList: [];
  levelNames: [];
  leader: number;
  apiStatus: number;// 0没权限 1有 创建api key的权限
  apiConfirm: number;// 0未同意创建apikey协议 1 创建过api key 有同意协议
  isHobbit: number;
  antiPhishingCode: string;
  isComplianceVerify: boolean;
  lastLoginDate: string;
  avatar: string;
  masterUserId: string;
  agentStatus: number;
  inInviteBlack: boolean;
  clientName?: string;
  badge: number;
  kycStatus: number; // kycStatus === 2 代表 kyc 通过
}
interface LoginUser {
  userId: string;
  registerType: number;
  mobile: string;
  nationalCode: string;
  email: string;
  bindGA: boolean;
  bindTradePwd: boolean;
  bindPassword: boolean;
  userType: number;
  verifyStatus: number;
  kycLevel: number;
  kycVerifyStatus: number;
  kycStatus: number;
  displayLevel: string;
  accounts: [];
  defaultAccountId: string;
  registerDate: string;
  source: string;
  inviteUserId: string;
  secondLevelInviteUserId: string;
  isAgent: number;
  customLabelList: [];
  antiPhishingCode: string;
  isComplianceVerify: false;
  lastLoginDate: string;
  lastLoginIp: string;
  platfrom: string;
}

interface KycInfo {
  openChannel: number;
  personal: {
    assetsProofStatus: {
      auditStatus: number;
    },
    bankApplyStatus: {
      auditStatus: number;
    },
    bankCardStatus: {
      auditStatus: number;
    },
    bankProofStatus: {
      auditStatus: number;
    },
    bankTransferStatus: {
      auditStatus: number;
    },
    baseInfoApplyStatus: {
      auditStatus: number;
    },
    certStatus: {
      auditStatus: number;
    },
    financialStatus: {
      auditStatus: number;
    },
    personalStatus: {
      auditStatus: number;
    },
    riskAssessmentStatus: {
      auditStatus: number;
    },
    signatureStatus: {
      auditStatus: number;
    },
    supplementaryStatus: {
      auditStatus: number;
    },
    vaAndRiskStatus: {
      auditStatus: number;
    },
    vaTestStatus: {
      auditStatus: number;
    },
  },
  supportRetail: boolean,
  totalAuditStatus: number,
  userType: number
}

interface VerifyInfo {
  current_level: number,
  displayLevel: string,
  verifyStatus: number,
  refusedReason: string,
}

// 用户HSK手续费相关信息 (需要登录)
export interface UserHskFeeInfo {
  whiteListFlag: boolean; // 是否是白名单，只有在白名单内才显示HSK入口
  vipFlag: boolean; // 是否使用vip费率
  vipLevelId: number; // 当前vip等级
  vipLevelName: string; // 当前等级名称[VIP0~6] （特殊费率需特殊处理）
  tmpVipLevelId: number; // 可以升级到的vip等级, 与 nextLevelId 可能不同(允许跨级升级)
  tmpVipLevelName: string; // 可以升级到的vip等级名称
  upgradeFlag: boolean; // 立即升级标识 [手动升级过一次后，便为false，默认值为true]
  specialFee: boolean; // 是否是特殊费率
  tradeUsdAmount: string; // T+1: 交易量usd
  assetUsdAmount: string; // T+1: 资产余额usd
  calDate: string; // 交易量更新时间
  meetUpgrade: boolean; // 升级按钮是否高亮
  fee: string; // 当前手续费 [当前用户等级，是展示fee, 还是展示 maker，taker, 由 fee 字段决定，如果 fee 字段存在且有值，则展示 fee，否则展示 maker/taker]
  originalFee: string; // 原始手续费
  makerFee: string;  // 当前maker费率
  originalMakerFee: string; // 原始maker费率
  takerFee: string; // 当前taker费率
  originalTakerFee: string; // 原始taker费率
  nextLevelId: number;  // 下一等级  [nextLevelId 与 vipLevelId 相等时，代表已是最高等级]
  nextLevelName: string; // 下一等级名称
  nextLevelGapTradeUsdAmount: string; // 升级还需要的现货交易量
  nextLevelFee: string; // 下一等级费率  [下一等级，是展示fee 还是展示 maker/taker,由 nextLevelFee 字段决定，如果 nextLevelFee 字段存在且有值，则展示 fee，否则是 maker/taker]
  nextLevelMakerFee: string; // 下一等级maker费率
  nextLevelTakerFee: string; // 下一等级taker费率
  cardType: number; // -1无卡  0金卡  1银卡
  cardEndTime: string; // 金卡/银卡过期时间
  cardPush: boolean; // 进入是否进行金卡/银卡弹窗展示 true：弹出，false：不弹
}

var lastRefresh = new Date();

export const useUserStore = defineStore('userStore', {
  state: () => {
    // 从localstorage 读取切换的子账户
    const storeSubAccountId = localStorage.getItem('sub_account') as string;
    // 从localstorage 读取forbidden ip信息
    const forbiddenMsg = localStorage.getItem('forbidden-ip') as string;
    return {
      userinfo: {} as Userinfo,
      baseUserInfo: {} as LoginUser,
      countriesList: [] as any,
      countryList: [] as any,
      regCountriesList: [] as any,
      unRegCountriesList: [] as any,
      regDeviceInfo: {} as any,
      // kyc信息
      kycInfo: {} as KycInfo,
      // kyc验证信息
      verifyInfo: {} as VerifyInfo,
      // 子账户
      subAccount: <any>null,
      // 当前子账户资产
      subAssets: [] as [],
      // 子账户map
      subAccountMap: <any>{},
      // vpn
      vpnWarningInfo: <any>{},
      // 子账户资产轮循控制器
      timeSubAsset: <any>null,
      // 登录状态定时器
      loginStateTimer: <any>null,
      // 登录状态是否过期
      loginStatusExpire: <boolean>false,
      // 在其他设备登录
      loginOtherEquipment: <boolean>false,
      // 邀请码
      inviteCode: <string>'',
      // 新版-子账户列表
      subAccountList: [] as any,
      currentSubAccountId: storeSubAccountId,
      refresh: <number>0,
      // api权限列表
      apiKeyAuthList: [] as any,
      levelInfo: {} as any,
      // 授权交易员列表
      operatorList: [] as any,
      // 各数据的加载状态
      loading: {
        userInfo: false
      },
      // 是否是opt账户
      isOPTAccount: <any>false,
      // 是否是处置账户
      isDisposalAccount: <any>false,
      // 用户活跃度过期时长
      activeOutTime: <any>{
        tradePage: 360, // 单位分钟，交易页面会话超时时间默认6小时
        notTradePage: 120
      },
      // 用户活跃度定时器计数
      activeCount: <number>0,
      // 用户活跃度检测定时器
      activeTimer: <any>null,
      // 超时提示
      activeOutStatus: <boolean>false,
      // 是否交易页面
      isTradePage: <boolean>false,
      // 用户HSK手续费等级相关信息
      userHskFeeInfo: {} as UserHskFeeInfo,
      // forbidden ip信息
      forbiddenIp: forbiddenMsg,
      // 注册登录页的防诈提示
      securityTipsVisible: true
    };
  },
  getters: {
    isUnPhoneReg(state): boolean {
      // 判断当前用户的ip在禁止手机号码注册的国家列表中
      if (state.unRegCountriesList.length > 0 && state.regDeviceInfo.countryCode) {
        let index = -1;
        for(let i = 0; i < state.unRegCountriesList.length; i++) {
          if (state.unRegCountriesList[i]?.shortName == state.regDeviceInfo.countryCode) {
            index = i;
            break;
          }
        }
        if (index >= 0) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    isLogin(state): boolean {
      return !!state.userinfo.userId;
    },
    mountedSubAccount(state): any {
      if (state.subAccountList && state.subAccountList.length > 0) {
        return state.subAccountList.filter((item: any) => item.trader.userIdStr === state.userinfo.userId);
      }
      return [];
    },
    currentSubAccount(state): any {
      var { userinfo, subAccountList, currentSubAccountId } = state;
      if (!currentSubAccountId) {
        currentSubAccountId = localStorage.getItem('sub_account') as string;
      }

      if (currentSubAccountId) {
        var list = subAccountList.filter((item: any) => item.userIdStr === currentSubAccountId);
        if (list && list.length > 0) {
          return subAccountList.filter((item: any) => item.userIdStr === currentSubAccountId)[0];
        } else {
          localStorage.removeItem('sub_account');
        }
      }

      if (userinfo.masterUserId == '0') {
        // 主账户
        return { nickname: userinfo.nickname, userIdStr: userinfo.userId, defaultAccountIdStr: userinfo.defaultAccountId };
      } else {
        // 授权交易员
        var result = [];
        if (subAccountList.length > 0) {
          result = subAccountList[0];
          localStorage.setItem('sub_account', result.userIdStr);
        }

        return result;
      }
    },
    isKyc(state) {
      return state.userinfo?.kycVerifyStatus === 2;
    },
    // 通过kyc
    isKycPass(state) {
      return state.userinfo?.kycStatus === CONST.kycStatus.PASS;
    },
    // 未通过kyc
    isNotKycPass(state) {
      return typeof state.userinfo?.kycStatus === 'number' && state.userinfo?.kycStatus !== CONST.kycStatus.PASS;
    },
    // 获取kyc信息
    getKycInfo(state) {
      const { kycInfo, verifyInfo } = state;
      // return kycInfo;
      if (!Object.keys(kycInfo).length) {
        return {};
      }

      if (!Object.keys(verifyInfo).length) {
        return kycInfo;
      }
      /**
       * needVedioVerify 三级kyc是否打开
       * userKycinfo: kyc各级别信息
       * currentLevelInfo 当前级别信息
       */
      const obj: any = {
        ...kycInfo
        // currentLevelInfo: kycInfo.userKycinfo.find(
        //   (data: any) => data.displayLevel == verifyInfo.displayLevel
        // ) || {}
      };

      return obj;

    },
    // 是否为子账户
    isSubAccount(state) {
      console.log(state.userinfo.userId, 'state.userinfo.userId');
      console.log(state.userinfo?.masterUserId, 'state.userinfo?.masterUserId');

      return state.userinfo.userId && state.userinfo?.masterUserId !== '0';
    },
    isOrgAccount(state) {
      // 包含企业 和 omnibus
      return state.userinfo.userId && (state.userinfo.userType == 2 || state.userinfo.userType == 8 || state.userinfo.userType == 4);
    },
    masterOperator(state) {
      // 管理员 作为 授权交易员
      return {
        nickname: state.userinfo.nickname,
        userIdStr: state.userinfo.userId,
        email: state.userinfo.email,
        userStatus: 1,
        defaultAccountIdStr: state.userinfo.defaultAccountId
      };
    }
  },
  actions: {
    closeSecurityTips() {
      this.securityTipsVisible = false;
    },
    changeForbiddenIp(val: string) {
      this.forbiddenIp = val;
    },
    changeLoginOtherEquipment(val: boolean) {
      this.loginOtherEquipment = val;
    },
    changeLoginStatus(val: boolean) {
      this.loginStatusExpire = val;
    },
    changeVpnWarningInfo(val: any) {
      this.vpnWarningInfo = { ...this.vpnWarningInfo, ...val };
    },
    changeOutStatus(val: boolean) {
      this.activeOutStatus = val;
    },
    // 监控用户长时间无操作自动登出
    checkUserActive(isTrade = false) {
      // 未登录
      if (!this.isLogin) return;
      // 会话超时后没有登出
      if (localStorage.getItem('user_login_expire')) {
        this.activeOutStatus = false; // 关闭会话超时弹窗
        localStorage.removeItem('user_login_expire'); // 删除会话超时的标记
        this.logOut();
        return;
      }

      this.clearTimer();
      this.isTradePage = isTrade;
      // 监听鼠标
      document.addEventListener('mousemove', this.initActiveCount);
      // 监听键盘
      document.addEventListener('keydown', this.initActiveCount);
      // 监听Scroll
      document.addEventListener('scroll', this.initActiveCount);
      // 设置定时器
      this.setTimer();
    },
    // 初始化activeCount
    initActiveCount() {
      this.activeCount = 0;
    },
    // 清除监听和定时器
    clearTimer() {
      document.removeEventListener('mousemove', this.initActiveCount);
      document.removeEventListener('keydown', this.initActiveCount);
      document.removeEventListener('scroll', this.initActiveCount);
      WorkerTimer.clearInterval(this.activeTimer);
      this.activeTimer = null;
      this.activeCount = 0;
    },
    // 定时器
    setTimer() {
      this.activeCount = 0;
      if (!this.activeTimer) {
        this.activeTimer = WorkerTimer.setInterval(this.cookieTimeout, 1000);
      }
    },
    // 超时判断
    cookieTimeout() {
      this.activeCount++;
      let outTime = this.activeOutTime.notTradePage;
      if (this.isTradePage) outTime = this.activeOutTime.tradePage;
      if (this.activeCount >= Number(outTime) * 60) {
        // 超时登出
        this.clearTimer();
        this.activeOutStatus = true;
        // this.logOut(true);
      }
    },
    // 改变out time
    changeOutTime(val: any) {
      this.activeOutTime = val;
    },
    getUserinfo() {
      this.loading.userInfo = true;
      return new Promise(async resolve => {
        const data: any = await ucApi.userinfo();
        this.loading.userInfo = false;

        if (data.code === 200) {
          resolve(true);
          this.userinfo = data.data || {};
          // 请求子账户
          this.getSubAccountList();
          this.getIsOPTAccount();
          if (!this.loginStateTimer) {
            this.loginStateLoop();
          }
        }

        resolve(false);
      });
    },
    loginStateLoop() {
      this.loginStateTimer = setTimeout(async() => {
        if (this.isLogin) {
          const res: any = await ucApi.isLogin();

          if ((res.success && res.data.userId) || res.code === 500) {
            this.loginStateLoop();
          } else {
            this.logOut();
          }
        } else {
          clearTimeout(this.loginStateTimer);
        }
      }, 5000);
    },
    logOut(clearOnly = false) {
      ucApi.passportLogout({}).then((res: any) => {
        // 登出成功
        removeCookie('account_id');
        removeCookie('au_token');
        removeCookie('c_token');
        removeCookie('user_id');
        removeCookie('glb_account_id');
        removeCookie('glb_au_token');
        removeCookie('glb_c_token');
        removeCookie('glb_user_id');
        removeCookie('group_user_id');
        removeCookie('group_user_token');
      });
    },
    getCountries() {
      if (this.countriesList.length) {
        return;
      }
      // 国家区号列表
      ucApi.getCountries({ businessType: 9 }).then((data: any) => {
        this.countriesList = [];
        if (data.code === 200) {
          data.data.countries
            // .filter((item:any) => item.nationalCode === "86")
            .forEach((item: never) => {
              this.countriesList.push(item);
            });
        }

      });
    },
    getCountry() {
      if (this.countryList.length) {
        return;
      }
      // 国家区号列表
      ucApi.getCountries({ businessType: 8 }).then((data: any) => {
        this.countryList = [];
        if (data.code === 200) {
          data.data.countries
            // .filter((item:any) => item.nationalCode === "86")
            .forEach((item: never) => {
              this.countryList.push(item);
            });
        }

      });
    },
    // 获取注册页面手机号支持国家列表
    getRegCountries() {
      if (this.regCountriesList.length) {
        return;
      }
      // 国家区号列表
      ucApi.getRegCountries({ businessType: 9 }).then((data: any) => {
        this.regCountriesList = [];
        if (data.code == 200 && data?.data?.length > 0) {
          data.data
            // .filter((item:any) => item.nationalCode === "86")
            .forEach((item: never) => {
              this.regCountriesList.push(item);
            });
        }
      });
    },
    // 获取注册页面禁用手机号码注册国家列表
    getUnRegCountries() {
      if (this.regCountriesList.length) {
        return;
      }
      // 国家区号列表
      ucApi.getRegCountries({ businessType: 104 }).then((data: any) => {
        this.unRegCountriesList = [];
        if (data.code == 200 && data?.data?.length > 0) {
          data.data
            // .filter((item:any) => item.nationalCode === "86")
            .forEach((item: never) => {
              this.unRegCountriesList.push(item);
            });
        }
      });
    },
    // 设备信息
    getRegDeviceInfo() {
      if (JSON.stringify(this.regDeviceInfo) != '{}') {
        return;
      }
      // 设备信息
      ucApi.deviceInfo({}).then((data: any) => {
        if (data.code == 200) {
          this.regDeviceInfo = data.data;
        }
      })
    },
    /**
     * 获取kyc信息
     */
    requestKycInfo() {
      kycApi.getUserKycInfo()
        .then((data: any) => {
          if (data.success) {
            this.kycInfo = data.data;
            localStorage.setItem('kycInfo', JSON.stringify(data.data));
          }
        });

    },
    /**
     * 验证信息
     */
    sendVerifyInfo() {
      userApi.getVerifyInfo()
        .then((data: any) => {
          if (data.success) {
            this.verifyInfo = data.data;
          }
        });
    },
    /**
     * 请求子账户列表
     *
     * @param isTransfer 是否是划转，在划转时请求子账户如果已有子账户那么不用重复请求
     * @returns
     */
    async sendtSubAccount(isTransfer = false) {
      if (isTransfer && this.subAccount && this.subAccount.length) {
        return;
      }

      const res: any = await assetApi.sendSubAccount();

      if (res.success) {
        this.subAccount = res.data;

        // 子账户map
        // res.data.forEach((item: any) => {
        //   if (!this.subAccountMap[item.accountId]) {
        //     this.subAccountMap[item.accountId] = item;
        //   }
        // });
      }
    },
    async getInviteCode() {
      const res: any = await userApi.getInviteCode({});
      if (res.success) {
        this.inviteCode = res.data.inviteCode;
      }
    },
    async getSubAccountList(params = { pageNum: 1, pageSize: 1000, userType: 5 }) {
      // 获取全部数据时pageSize传1000，超过999
      const res: any = await subApi.getSubListPaging(params);
      if (res.success) {
        this.subAccountList = res.data.data;
      }
    },
    async getOperatorList(params = { pageNum: 1, pageSize: 1000, userType: 6 }) {
      // 获取全部数据时pageSize传1000，超过999  userType 5为交易员 6 操作员，不传为全部
      const res: any = await subApi.getSubListPaging(params);
      if (res.success) {
        this.operatorList = res.data.data;
      }
    },
    async getApiKeyAuthList() {
      const res: any = await subApi.getApiKeyAuthList({});
      if (res.success) {
        this.apiKeyAuthList = res.data;
      }
    },
    // 获取用户等级信息
    async getLevelInfo() {
      const res: any = await vipApi.levelConfigs();
      if (res.success) {
        this.levelInfo = res.data;
      }
    },
    switchSubAccount(subAccount: any, toPath = '', isNotReload:any) {
      if (subAccount && subAccount.userIdStr !== this.userinfo.userId) {
        this.currentSubAccountId = subAccount.userIdStr;
        localStorage.setItem('sub_account', this.currentSubAccountId);
      } else {
        this.currentSubAccountId = '';
        localStorage.removeItem('sub_account');
      }
      if (toPath) {
        window.location.replace(toPath);
      } else {
        if (isNotReload) {

        } else {
          window.location.reload();
        }   
      }

    },
    async getIsOPTAccount() {

      userApi.queryAccountList({}).then((res: any) => {
        if (res.success) {
          const isOpt = res.data?.filter((item:any) => item.accountType == CONST.ACCOUNT_TYPE.OPT).length > 0;
          // 存在OPT账户
          if (isOpt) {
            this.isOPTAccount = isOpt;
          }

          // 存在处置账户
          const isDisposalAccount = res.data?.filter((item:any) => item.accountType == CONST.ACCOUNT_TYPE.OPT_DISPOSAL).length > 0;

          this.isDisposalAccount = isDisposalAccount;// 判断是否是处置账户
        }
      });
    },

    addRefreshFlag() {
      this.refresh++;
      this.getSubAccountList();
      this.getOperatorList();
    },

    // 获取用户HSK等级相关信息
    async getUserHskFeeInfo() {
      try {
        const res: any = await hskFeeApi.getUserVipInfo({});
        if (res && String(res?.code) === '200') {
          const info = res?.data || {};
          this.userHskFeeInfo = { ...info };
        }
      } catch {}
    }

  }
});
